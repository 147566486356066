import React, {useEffect, useState} from "react"
import {Container,Table, Dropdown} from 'react-bootstrap';
import { Link } from "gatsby";
import {config} from '../components/Common/constant';
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"

import SortIcon from "../assests/images/sort-icon.svg";

import { useContainerContext } from "../store/ContainerContext";
//import {getUser} from "../services/auth";
import {numberWithCommas} from "../components/utils/common";
import SearchForm from "../components/modules/search-form";
import TableLineShrimmer from '../components/shrimmer/TableLineShrimmer';
import {isLoggedIn, isBrowser, logout, getUser } from "../services/auth"

import { SEARCH_SELLER_DATA, SEARCH_PROPERTY_DATA } from "../queries/common_use_query";

import dateFormat from 'dateformat';

const Search_Result = () => {

  const context = useContainerContext();
  const { logged_user_data } = context.state;
  //const user = getUser();

  const [ search_text, setSearchText ] = useState('dddd');

  const handlechange = (event) => {
    // remove initial empty spaces
    var search_val = event.target.value.trimStart();
    setSearchText(search_val !== "" ? search_val : 'dddd');
  }

  const createLink = (property_id, seller_email, user_email) => {
    if (isBrowser()) {
        localStorage.setItem('create_present', JSON.stringify({'property_id':property_id, 'seller_email': seller_email, 'user_email':user_email}));
    }
  }

  const { loading:seller_loading, error:seller_error, data:seller_data } = SEARCH_SELLER_DATA(logged_user_data.email, search_text);
  const { loading:property_loading, error:property_error, data:property_data } = SEARCH_PROPERTY_DATA(logged_user_data.email, search_text);
  
  //console.log("search_data ==> seller_data", seller_data && seller_data);
  //console.log("search_data ==> property_data", property_data && property_data);

  console.log("search_data ==> seller_data", seller_data, property_data);

  return(
    <>
      <SEO title="Search" description="Search" />

      <div className="dashboard-wrapper">
        <LeftNav page_name="Search" />
        {/* sidebar */}

        <div className="dashboard-content srch_cnt">
          <Container>
              <div className="content-wrapper">
                <div className="top-content">
                    <h2><strong>Search Property or Seller</strong></h2>

                    <SearchForm placeholder="Search here..." handlechange={handlechange} />
                </div>

                {/* top-content */}
                <div className="card-block">
                    <div className="table-wrap">
                        <Table className={`${(seller_data && seller_data.properties.length === 0) && (property_data && property_data.properties.length === 0) ? 'no_rec_tbl_cls' : ''}`} responsive>
                            <thead>
                                <tr className="table-head">
                                    <th>Seller Name</th>
                                    <th>Property Address</th>
                                    <th className="wdth3">Price Paid</th>
                                    <th className="wdth3">Price Expectancy</th>
                                    <th className="wdth3">Property Type</th>
                                </tr>
                            </thead>
                            <tbody>
                              {
                                search_text !== 'dddd' && seller_loading && property_loading ? (
                                  <TableLineShrimmer item_count={3} />
                                ) : <>
                                    {
                                      seller_data && seller_data.properties.length > 0 && seller_data.properties.map((property, index) => {
                                        return(
                                          <tr>
                                            <td>{property.seller_name}</td>
                                            <td><Link to={`${config.property_details}/${property.id}`} onClick={() => {createLink(property.id, property.seller_email, property.user_email)}}>{property.address}</Link></td>
                                            <td>{property.price_paid ? '£'+numberWithCommas(property.price_paid.toFixed(2)) : '--'}</td>
                                            <td>{property.price_expectancy ? '£'+numberWithCommas(property.price_expectancy.toFixed(2)) : '--'}</td>
                                            <td>{property.property_type ? property.property_type : '--'}</td>                     
                                          </tr>
                                        )
                                      })
                                    }
                                    { 
                                      property_data && property_data.properties.length > 0 && property_data.properties.map((property, index) => {
                                        return(
                                          <tr>
                                            <td>{property.seller_name}</td>
                                            <td><Link to={`${config.property_details}/${property.id}`} onClick={() => {createLink(property.id, property.seller_email, property.user_email)}}>{property.address}</Link></td>
                                            <td>{property.price_paid ? '£'+numberWithCommas(property.price_paid.toFixed(2)) : '--'}</td>
                                            <td>{property.price_expectancy ? '£'+numberWithCommas(property.price_expectancy.toFixed(2)) : '--'}</td>
                                            <td>{property.property_type ? property.property_type : '--'}</td>                     
                                          </tr>
                                        )
                                      })
                                    }
                                </>
                              }

                              {
                                  search_text === 'dddd' ? (<td colSpan={5} className="noRecMsg">Search property or seller...</td>) : (property_data && property_data.properties.length === 0) && (seller_data && seller_data.properties.length === 0) && (<td colSpan={5} className="noRecMsg">There are no records!</td>)
                              }
                                
                            </tbody>
                        </Table>
                    </div>
                    {/* table-wrap */}
                </div>
                {/* card-block */}

              </div>
          </Container>
          {/* container */}
        </div>
        {/* dashboard-content */}
      </div>
    </>
  )

}

export default Search_Result